import { Model } from '../abstract/model.abstract';
import dayjs from '../utils/dayjs-util';
export class UserAccountModel extends Model {
    coins: number;
    tokens: number;
    energy: number;
    level: number;
    levelExp: number;
    pveLevel: number;
    pvpRank: number;
    activePveLevel: number;
    maxEnergy: number;
    energySpentAt: string;

    nextLvlPercent(expNeeded: number = 100) {
        const percent = (this.levelExp / expNeeded) * 100;
        return percent > 100 ? 100 : percent;
    }

    get currentEnergy() {
        const energy = dayjs()
            .utc()
            .diff(dayjs(this.energySpentAt).utc(), 'hours');

        if (energy) {
            return this.energy + Math.floor(energy) > this.maxEnergy
                ? this.maxEnergy
                : this.energy + Math.floor(energy);
        }

        return this.energy;
    }
}
